import { useEffect } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useLazyGetAssociationStripeLinksQuery } from 'manage-tritag/services/api/endpoints/associations'
import Layout from 'manage-tritag/components/layout'

const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        padding: '1rem',
      },
    }
  }}
  display: flex;
  margin-top: 8rem;
  align-items: center;
  flex-direction: column;
`

const PaymentsOnboarding = () => {
  const { user } = useAuth0()

  const [getStripeLinks, { data: stripeLinks, isError, isLoading }] =
    useLazyGetAssociationStripeLinksQuery()

  useEffect(() => {
    if (user && user['https://tritagrugby.com/user_metadata']) {
      const userMetaData = user['https://tritagrugby.com/user_metadata']
      if (userMetaData?.activeRole?.entityId) {
        getStripeLinks(userMetaData?.activeRole?.entityId)
      } else {
        console.error('Unable to find user association stripe links')
      }
    }
  }, [getStripeLinks, user])

  useEffect(() => {
    if (stripeLinks?.data?.onboarding?.url) {
      window.open(stripeLinks?.data?.onboarding?.url, '_blank')
    }
  }, [stripeLinks])

  return (
    <Layout url="/payments/onboarding">
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      )}
      {!isLoading && isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Typography>Something went wrong!</Typography>
        </Box>
      )}
      {!isLoading && !isError && (
        <StyledBox>
          <div>
            <Typography variant="h4" style={{ marginBottom: '2rem' }}>
              Redirecting...
            </Typography>
            <Typography variant="h5" style={{ marginBottom: '2rem' }}>
              You are being redirected to Stripe to complete your onboarding.
            </Typography>
            {stripeLinks?.data?.onboarding?.url && (
              <Typography variant="h5" style={{ marginBottom: '2rem' }}>
                If you are not redirected within a few seconds please click{' '}
                <a
                  href={stripeLinks?.data?.onboarding?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </Typography>
            )}
          </div>
        </StyledBox>
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(PaymentsOnboarding)
